export function extractMentions(inputString, regex) {
    var mentions = inputString.match(regex) || [];
    var uniqueMentions = Array.from(new Set(mentions.map(function (mention) {
        return mention.substring(mention.lastIndexOf('(') + 1, mention.lastIndexOf(')'));
    })));
    var index = uniqueMentions.indexOf('everyone');
    if (index !== -1) {
        uniqueMentions.splice(index, 1);
    }
    return uniqueMentions;
}
